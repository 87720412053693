import { getObjectKeys } from "@avvoka/shared"

// Type for localization data, value can be string or another level of localization
interface LocalizationData {
  [T: string]: string | LocalizationData
}

type LocalizationMethod = (key: string, args?: Record<string, unknown>) => string

// Assert correct types
function _isNamespace (source: ReturnType<typeof _retrieve>): source is LocalizationData {
  return !!source && typeof source === 'object'
}

function _isLocalization (source: ReturnType<typeof _retrieve>): source is string {
  return typeof source === 'string'
}

// Helper to retrieve data from nested json
function _retrieve (source: LocalizationData, path: string): string | LocalizationData | null {
  const items = path.split('.')

  // Dig in the localization data and try to retrieve the text
  let text: string | LocalizationData = source
  for (const item of items) {
    text = text[item]
    if (!text || typeof text !== 'object') {
      break
    }
  }

  return text
}

// Generate localized text from path
function _localizePath (path: string, namespace?: string) {
  if (window.isDevelopment) {
    return namespace ? `${namespace}.${path}` : path
  } else {
    const items = path.split('.')
  
    return items[items.length - 1].split('_').map((word) => word.replace(/^\w/, (c) => c.toUpperCase())).join(' ')
  }
}

// Global method for localization, queries localization data
function _localize (source: LocalizationData, path: string, args?: Record<string, unknown>, namespace?: string) {
  let text = _retrieve(source, path)

  // Check whether text is valid (must be string)
  if (!_isLocalization(text)) {
    console.warn(`[Localization] Missing or invalid translation for '${namespace ? `${namespace}.` : ''}${path}' `)

    return _localizePath(path, namespace)
  }

  // If args is present, try to insert variables
  if (args && typeof args === 'object') {
    for (const key of getObjectKeys(args)) text = text.replaceAll(`%{${key}}`, String(args[key]))
  }

  return text
}

// Local method to avoid duplication
const globalLocalize = (key: string, args?: Record<string, unknown>) => _localize(window.i18n_Data, key, args)

export function useLocalize (namespace: string = ''): LocalizationMethod & {
  /**
   * Localize key without prepending current namespace
   */
  global: LocalizationMethod
} {
  const source = namespace ? _retrieve(window.i18n_Data, namespace) : window.i18n_Data
  if (!_isNamespace(source)) {
    console.warn(`[Localization] Namespace '${namespace}' is not a valid namespace`)

    // Return 'fake' localization function
    const localize = (key: string, _args?: Record<string, unknown>) => _localizePath(key, namespace)
    localize.global = globalLocalize

    return localize
  }

  const localize = (key: string, args?: Record<string, unknown>) => _localize(source, key, args, namespace)
  localize.global = globalLocalize

  return localize
}

declare global {
  interface Window {
    i18n_Data: LocalizationData
    i18n_TZName: string

    isDevelopment: boolean
  }
}

if (import.meta.env.VITEST) {
  // If vitestenv, set variables manually here
  window.i18n_Data ??= {}
  window.i18n_TZName ??= 'Europe/London'
  window.isDevelopment ??= true
}

export const _spec = {
  _isNamespace,
  _isLocalization,
  _retrieve,
  _localize
}